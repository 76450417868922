import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal,Label,FormGroup,Input } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../Common/CustomPagination";
import {DataTabeSearch} from '../Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { Multiselect } from 'multiselect-react-dropdown';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Loader from "../../../pages/Loader/Loader";

import { createPark,parkTicketTransactionList,editPark,getDistrictDropdown,getThanaDropdown,getDivisionDropdown,searchParkTicketTransaction} from '../../../store/parkTicketTransaction/actions';

class ParkTicketTransaction extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                loading: false,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                startDate: new Date(),
                endDate: new Date(),
                Park: {
                    
                },
                park_Ticket_Transaction_List: []
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    districtHandleChange = (event) => {
        event.preventDefault();
        const { target: { name, value } } = event;
         this.fetchDistrict(event.target.value);
        // alert(event.target.value);
        //  this.updateEmployeeObject(name, value);
      }
      
    thanaHandleChange = (event) => {
        event.preventDefault();
        const { target: { name, value } } = event;
         this.fetchThana(event.target.value);
        //  this.updateEmployeeObject(name, value);
      }
      
fetchDistrict = (value) => {

  getDistrictDropdown(value)
      .then((response) => {
          this.setState({
              district: response.data
          })
      }, (respose) => {

      })
      .catch((err) => {
          console.log(err);
      })
}


fetchThana = (value) => {

  getThanaDropdown(value)
      .then((response) => {
           console.log("thana",response);
          this.setState({
              thana: response.data
          })
      }, (respose) => {

      })
      .catch((err) => {
          console.log(err);
      })

}

fetchDivision = () => {

    getDivisionDropdown()
        .then((response) => {
             console.log("division",response);
            this.setState({
                division: response.data
            })
        }, (respose) => {
  
        })
        .catch((err) => {
            console.log(err);
        })
  
  }

    componentDidMount()
    {
            this.fetchparkTicketTransactionList();   
            
            this.fetchDivision();         
    }

    fetchparkTicketTransactionList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        parkTicketTransactionList(options).then((response) => {
            this.setState({
                park_Ticket_Transaction_List :response.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
    tog_scroll = (Park) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.Park = Park;
        this.fetchDistrict(Park.f_division_id);
        this.fetchThana(Park.f_zilla_id);
        this.removeBodyCss();
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }


      autoSearchInputChange = (event) => {
        event.preventDefault();
        const { target: { name, value } } = event;
        // this.updateDesignationObject(name, value);
      }
      
      // handleValidSubmit
        handleValidSubmit(event, values) {       
            this.state.loading = true;   
            searchParkTicketTransaction(values).then((response) => {
                this.setState({
                    park_Ticket_Transaction_List :response, 
                    loading: false,
                });
            })
            .catch((err) => {
                this.state.loading = false;   

                console.log(err);
            })

            this.form && this.form.reset();    
        }
       
       handleEditSubmit(event, values) {  
            this.props.editPark(values);
            this.setState(prevState => ({
                modal_scroll: !prevState.modal_scroll,
              }));
             this.fetchparkTicketTransactionList();  
       }

       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchparkTicketTransactionList();
        });
       }

       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchparkTicketTransactionList();
            });
        }

    render() {
        if (this.state.loading) return <Loader />;
        let divisionList = [];
        let districtList = [];
        let thanaList = [];

        if (this.state.hasOwnProperty('division')) {
            divisionList =  this.state.division;
          }

        if (this.state.hasOwnProperty('district')) {
            districtList =  this.state.district;
          }

        if (this.state.hasOwnProperty('thana')) {
            thanaList =  this.state.thana;
          }
        let parkTicketTransactionLists = [];
        let paginator;
        if (this.state.hasOwnProperty('park_Ticket_Transaction_List')) {
            parkTicketTransactionLists = this.state.park_Ticket_Transaction_List.data;
            paginator = {
                'currentPage': this.state.park_Ticket_Transaction_List.current_page,
                'pagesCount': this.state.park_Ticket_Transaction_List.last_page,
                'total': this.state.park_Ticket_Transaction_List.total,
                'to': this.state.park_Ticket_Transaction_List.to,
                'from': this.state.park_Ticket_Transaction_List.from,
            }
        }
        const {id,park_name_en,park_name_bn,f_division_id,f_zilla_id,f_thana_id,address,priority,status} = this.state.Park;
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Park Ticket Transactions" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                        <Col lg="12" md="12">
                        <Card>
                                    <CardBody>
                                        
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }} ref={c => (this.form = c)}>

                                            <FormGroup row>
                                                
                                                <div className="col-md-6">
                                                    <label>Date Range</label>
                                                    <DatePicker
                                                        selected={this.state.startDate}
                                                        className="form-control" 
                                                        onChange={date => this.setState({startDate: date})}
                                                        selectsStart
                                                        startDate={this.state.startDate}
                                                        // endDate={this.state.endDate}
                                                    />
                                                    <DatePicker
                                                        selected={this.state.endDate}
                                                        className="form-control" 
                                                        onChange={date => this.setState({endDate: date})}
                                                        selectsEnd
                                                        // startDate={this.state.startDate}
                                                        endDate={this.state.endDate}
                                                        // minDate={this.state.startDate}
                                                    />
                                                        <AvField    type="hidden" name="startDate" id="start_date"  value={moment(new Date(this.state.startDate)).format("YYYY-MM-DD")} />
                                                        <AvField    type="hidden" name="endDate" id="end_date" value={moment(new Date(this.state.endDate)).format("YYYY-MM-DD")} />

                                                    {/* <AvField className="form-control" label=" Date"  type="date" name="search_date" id="search_date" value={this.state.searchDate} onChange={(e,v)=>this.setState({searchDate: v})} required/> */}
                                                </div>

                                                
                                            </FormGroup>

                                                 
                                             <div className=" mt-4">
                                                 <Button type="submit" color="success">Search</Button>
                                             </div>
                                         </AvForm>
                                    </CardBody>
                                </Card>
                        </Col>
                        <Col lg="12" md="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Park Ticket Transactions List</CardTitle>
                                        <div className="table-responsive-sm">
                                        <DataTabeSearch handleInputChange={this.handleInputChange}/>
                                            <Table className="table mb-0">

                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Code</th>
                                                        <th>Ticket No</th>
                                                        <th>Ticket Price</th>
                                                        <th>Ticket Date</th>
                                                        <th>Booker Name</th>
                                                        <th>Booker Phone</th>
                                                        <th>Is Family</th>
                                                        <th>Status</th>
                                                        {/* <th>Action</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {parkTicketTransactionLists&&parkTicketTransactionLists.map((parkTicketTransactionList, index) =>
                                                    <tr key={parkTicketTransactionList.id .toString()}>
                                                        <td scope="row">{index + 1 + ((this.state.page - 1) * this.state.per_page)}</td>
                                                        <td>{parkTicketTransactionList.code}</td>
                                                        <td>{parkTicketTransactionList.ticket_no}</td>
                                                        <td>{parkTicketTransactionList.ticket_price}</td>
                                                        <td>{parkTicketTransactionList.ticket_date}</td>
                                                        <td>{parkTicketTransactionList.ticket_booking_name}</td>
                                                        <td>{parkTicketTransactionList.ticket_booking_phone}</td>
                                                        <td>{parkTicketTransactionList.family_id == 1?"Yes":"No"}</td>
                                                        <td>{parkTicketTransactionList.status == 1?"Active":"Deactive"}</td>

                                                        
                                                        {/* <td><button type="button"
                                                               className="btn btn-sm btn-primary waves-effect waves-light"
                                                               onClick={() => { this.tog_scroll(parkTicketTransactionList) }} 
                                                               data-toggle="modal"
                                                             >Edit</button>
                                                        </td> */}

                                                       </tr>
                                                )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                     <CardFooter className="p-6">
                                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </CardFooter>
                                      }
                                </Card>
                                {/* <Modal
                                 isOpen={this.state.modal_scroll}
                                   toggle={this.tog_scroll}
                                   scrollable={true}
                                   >
                                   <div className="modal-header">
                                       <h5 className="modal-title mt-0">{park_name_en}</h5>
                                       <button
                                       type="button"
                                       onClick={() =>
                                           this.setState({ modal_scroll: false })
                                       }
                                       className="close"
                                       data-dismiss="modal"
                                       aria-label="Close"
                                       >
                                       <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div className="modal-body">
                                   <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditSubmit(e,v) }}>
                                       {this.props.msg && this.props.msg ? (
                                           <Alert color="success">
                                               ParkInfo Updated Successfully!
                                           </Alert>
                                           ) : null}
                                                <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Park Name"  id="park_name_en" name="park_name_en" value={park_name_en} required/>
                                                     <AvField name="id" id="id" value={id}  type="hidden"  />
                                                </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Park Name(Bangla)"  id="park_name_bn" name="park_name_bn" value={park_name_bn} required/>
                                                 </div>
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="textarea" label="Address"  id="address" name="address" value={address} required/>
                                                 </div>
                                                 <div className="form-group col-md-12">
                                                    <AvField type="select"className="custom-select" label="Select Division" name="f_division_id" id="f_division_id" value={f_division_id}  onChange={(e) => this.districtHandleChange(e)} className="form-control" required>
                                                            <option value=''>Select Division</option>
                                                            {divisionList.map((division, index) =>
                                                        <option  value={division.division_id}>{division.division_name}</option>
                                                      )}
                                                    </AvField>
                                                </div>

                                                <div className="form-group col-md-12">
                                                    <AvField type="select" className="custom-select" label="Select District" name="f_zilla_id" id="f_zilla_id" value={f_zilla_id}  onChange={(e) => this.thanaHandleChange(e)} className="form-control" required>
                                                        <option value=''>Select District</option>
                                                            {districtList.map((district, index) =>
                                                        <option  value={district.district_id}>{district.district_name}</option>
                                                      )}
                                                    </AvField>
                                                </div>

                                                <div className="form-group col-md-12" >
                                                    <AvField type="select" className="custom-select" label="Select Thana" name="f_thana_id" id="f_thana_id" value={f_thana_id}  onChange={(e) => this.autoSearchInputChange(e)} className="form-control" required>
                                                        <option value=''>Select Thana</option>
                                                        {thanaList.map((thana, index) =>
                                                        <option  value={thana.thana_id}>{thana.thana_name}</option>
                                                      )}
                                                    </AvField>
                                                </div>

                                                    <div className="form-group col-md-12 " >
                                                        <AvField type="select" className="custom-select form-control" label="Status" name="status" id="Status" value={status} required  >
                                                            <option value=''>Select Status</option>
                                                            <option value="1">active</option>
                                                            <option value="2">Deactive</option>
                                                        </AvField>
                                                    </div>

                                                    <div className="form-group col-md-12 ">
                                                         <AvField className="form-control" type="text" label="Priority"  id="priority" name="priority"  value={priority} required/>
                                                    </div>
                                               
                                           <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Save</button>
                                           </div>
                                   </AvForm>
                                   </div>
                               </Modal> */}
                            </Col>
                            
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.ParkingSpaceName;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps)(ParkTicketTransaction));
