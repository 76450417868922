import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import ParkingSpaceName from "./parkingSpaceName/reducer";
import ParkingRate from "./parkingRate/reducer";
import ParkingSlot from "./parkingSlot/reducer";
import DeviceSetings from "./deviceSetings/reducer";
import Customer from "./customer/reducer";
import CustomerVehicleMap from "./customerVehicleMap/reducer";
import Bill from "./bill/reducer";
import ParkingTransaction from "./park/reducer";
import ExpenseGroup from "./expenseGroup/reducer";
import ExpenseType from "./expenseType/reducer";
import ExpenseGroupTypeMap from './expenseGroupTypeMap/reducer';
import ExpenseTransaction from './expenseTransaction/reducer';
import Role from './role/reducer';
import User from './user/reducer';
import ViewDetails from "./viewDetails/reducer";
import parkInfoDetials from "./parkInfo/reducer";
import parkRate from "./parkRate/reducer";
import parkDeviceSetings from "./parkDeviceSetings/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ParkingSpaceName,
  DeviceSetings,
  ParkingRate,
  ParkingSlot,
  CustomerVehicleMap,
  Bill,
  ParkingTransaction,
  ExpenseGroup,
  ExpenseType,
  ExpenseGroupTypeMap,
  ExpenseTransaction,
  Role,
  User,
  Customer,
  ViewDetails,
  parkInfoDetials,
  parkRate,
  parkDeviceSetings
});

export default rootReducer;
