import { all } from 'redux-saga/effects';

//public
import AccountSaga from './auth/register/saga';
import AuthSaga from './auth/login/saga';
import ForgetSaga from './auth/forgetpwd/saga';
import ProfileSaga from './auth/profile/saga';
import ParkingSlotSaga from './parkingSlot/saga';
import ParkingSpaceNameSaga from './parkingSpaceName/saga';
import ParkingRateInfoSaga from './parkingRate/saga';
import DeviceSettingsSaga from './deviceSetings/saga';
import CustomerSaga from './customer/saga';
import CustomerVehicleMappingSaga from './customerVehicleMap/saga';
import BillSaga from './bill/saga';
import ParkingTransactionSaga from './park/saga';
import ExpenseGroupSaga from './expenseGroup/saga';
import ExpenseTypeSaga from './expenseType/saga';
import ExpenseGroupTypeMapSaga from './expenseGroupTypeMap/saga';
import ExpenseTransactionSaga from './expenseTransaction/saga';
import RoleSaga from './role/saga';
import UserSaga from './user/saga';
import ViewDetailsSaga from './viewDetails/saga';
import LayoutSaga from './layout/saga';
import ParkSaga from './parkInfo/saga';
import ParkRateSaga from './parkRate/saga';
import ParkDeviceSettingsSaga from './parkDeviceSetings/saga';


export default function* rootSaga() {
    yield all([
        //public
        AccountSaga(),
        AuthSaga(),
        ForgetSaga(),
        ProfileSaga(),
        ParkingSlotSaga(),
        ParkingRateInfoSaga(),
        ParkingSpaceNameSaga(),
        DeviceSettingsSaga(),
        CustomerSaga(),
        CustomerVehicleMappingSaga(),
        BillSaga(),
        ParkingTransactionSaga(),
        ExpenseGroupSaga(),
        ExpenseTypeSaga(),
        ExpenseGroupTypeMapSaga(),
        ExpenseTransactionSaga(),
        RoleSaga(),
        UserSaga(),
        LayoutSaga(),
        ViewDetailsSaga(),
        ParkSaga(),
        ParkRateSaga(),
        ParkDeviceSettingsSaga()
    ])
}