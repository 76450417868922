import { PARK_OUT_ERROR,PARK_IN_SUCCESS,EDIT_PARK_IN,SHOW_PARK_IN,CREATE_PARK_IN ,REMOVE_PARK_IN,RESTORE_PARK_IN} from './actionTypes';
import {getApi} from "../../services/httpService";

export const createParkIn = (parkingTransaction) => {
    return {
        type: CREATE_PARK_IN,
        payload: { parkingTransaction }
    }
}

export const showParkIn = (parkingTransaction_id) => {
    return {
        type: SHOW_PARK_IN,
        payload: { parkingTransaction_id }
    }
}

export const editParkIn = (parkingTransaction) => {
    return {
        type: EDIT_PARK_IN,
        payload: { parkingTransaction }
    }
}

export const restoreParkIn = (parkingTransaction) => {
    return {
        type: RESTORE_PARK_IN,
        payload: { parkingTransaction }
    }
}


export const removeParkIn = (parkingTransaction) => {
    return {
        type: REMOVE_PARK_IN,
        payload: { parkingTransaction }
    }
}

export const parkInSuccess = (msg) => {
    console.log('msg',msg)
    return {
        type: PARK_IN_SUCCESS,
        payload: msg
    }
}


export function parkOutShow(parkingTransaction_id){
    return getApi().get('parkingTransaction/'+parkingTransaction_id)
}

export function searchByParkOutReport(data){
    return getApi().post('report/parkout',data);
}

export function searchByExpenseReport(data){
    return getApi().post('report/expense',data);
}

export function searchByDayTopSheet(data){
    return getApi().post('report/topSheet',data);
}


export function searchByParkInReport(data){
    return getApi().post('report/parkin',data);
}

export function searchParkInVehicle(keyword,params){
    return getApi().get('parkingTransaction/search/parkout/'+keyword,{
        params: params
    });
}

export function parkInList(params){
    return getApi().get('parkingTransaction/list/webParkInList',{
        params: params
    })
}

export function deletedParkInList(params){
    return getApi().get('parkingTransaction/list/deletedParkInList',{
        params: params
    })
}

export function parkOutList(params){
    return getApi().get('parkingTransaction/list/parkOut',{
        params: params
    })
}

export function shiftSetupList(params){
    return getApi().get('shiftSetup/list',{
        params: params
    })
}

export function searchByRateTypeList(vehicle,space_name,rate){
    return getApi().get('parkingRateInfo/search/'+vehicle+'/'+space_name+'/'+rate)
}

export function rateTypeList(params){
    return getApi().get('rateType/list',{
        params: params
    })
}

export function vehicleTypeList(params){
    return getApi().get('vehicleType/list',{
        params: params
    })
}



export const parkOutError = (error) => {
    return {
        type: PARK_OUT_ERROR,
        payload: error
    }
}
